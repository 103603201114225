<script setup lang="ts">
import {
  ref, computed, watch,
} from 'vue';
import { useVuelidate } from '@vuelidate/core';
import { storeToRefs } from 'pinia';
import { useRoute } from 'vue-router';
import { useAppStore } from '@/store/app';
import * as validators from '@vuelidate/validators';
import { useI18n } from 'vue-i18n';
import { useGoTo } from 'vuetify';

// Composables
import useValidationErrors from '@/composables/useValidationErrors';
import useErrorMessages from '@/composables/useErrorMessages';
import setNullState from '@/helpers/SetNullStore';

const goTo = useGoTo();

const route = useRoute();

// Store
const {
  loading, onlyCV, candidatoVerify, initialState: state,
} = storeToRefs(useAppStore());
const { verifyCandidato } = useAppStore();

const { t } = useI18n();

// Fecha mayor a 18 años y menor de 65
const fechaMayor = (new Date(new Date().setFullYear(new Date().getFullYear() - 18)));
const fechaMin = (new Date(new Date().setFullYear(new Date().getFullYear() - 65)));

const valid = ref(false);
const tipoItems = computed(() => ([
  { text: t('$vuetify.Cedula'), value: 'Z1' },
  { text: t('$vuetify.Pasaporte'), value: 'Z2' },
]));

const { createI18nMessage } = validators;
const messagePath = ({ $validator, $params }: any) => {
  const fieldName = $params?.$field || $validator;
  return `$vuetify.validations.${fieldName}`;
};

const withI18nMessage = createI18nMessage({ t, messagePath });
const required = withI18nMessage(validators.required);
const requiredIf = (condition: () => boolean) => withI18nMessage(validators.requiredIf(condition));
const email = withI18nMessage(validators.email);
const minLength = (length: number) => withI18nMessage(validators.minLength(length));

const rules = computed(() => ({
  tipoID: {
    required,
  },
  identificacion: {
    required,
    minLength: minLength((
      state.value.tipoID && state.value.tipoID.value === 'Z1') ? 11 : 5),
  },
  nombre: {
    required,
    minLength: minLength(3),
  },
  primerApellido: {
    required,
    minLength: minLength(3),
  },
  segundoApellido: {
    minLength: minLength(3),
  },
  email: {
    requiredIf: requiredIf(() => state.value.tieneCorreo),
    email: (state.value.tieneCorreo) ? email : false,
  },
  fechaNacimiento: {
    required,
  },
}));

const v$ = useVuelidate(rules, state);

const errors = computed(() => useValidationErrors<any>(v$.value.$errors));

const { getErrorMessages } = useErrorMessages(v$, errors);

const optionsCed = ref({
  mask: '###-#######-#',
  eager: true,
});

watch(
  () => state.value.tipoID,
  (newVal) => {
    if (newVal && newVal.value === 'Z1') {
      state.value.identificationActive = false;
      optionsCed.value = {
        mask: '###-#######-#',
        eager: true,
      };
    } else {
      state.value.identificationActive = false;
      optionsCed.value = {
        mask: '',
        eager: true,
      };
    }
  },
);

const cerrarMenu = () => {
  if (state.value.fechaNacimiento) state.value.menu = false;
  state.value.fechaMode = 'year';
  state.value.fechaNacimiento_month = -1;
  if (state.value.fechaNacimiento) {
    const FechaNacFormat = state.value.fechaNacimiento.toISOString().slice(0, 10);
    const [year, month, day] = FechaNacFormat.split('-');
    state.value.fechaNacimiento_format = `${day}-${month}-${year}`;
  }
};

const submitForm = async () => {
  const check = await v$.value.$validate();
  if (check) {
    await verifyCandidato(route);
  }
  await goTo('.v-field--error', {
    offset: -80,
    easing: 'easeInQuad',
  });
};

</script>

<template>
  <v-form v-model="valid" :disabled="onlyCV || candidatoVerify || loading">
    <v-container class="pa-0">
      <v-row no-gutters>
        <v-col
          cols="12"
          md="3"
        >
          <v-sheet class="ma-2">
            <SelectComponent
              v-model="state.tipoID"
              :label="$t('$vuetify.tipoID')"
              :items="tipoItems"
              item-text="text"
              item-value="value"
              :error="v$.tipoID.$error"
              :errorMessages="getErrorMessages('tipoID')"
            />
          </v-sheet>
        </v-col>
        <v-col
          cols="12"
          md="3"
        >
          <v-sheet class="ma-2">
            <InputTextComponent
              v-model="state.identificacionMSK"
              :label="$t('$vuetify.identificacion')"
              :inputmode="state.tipoID && state.tipoID.value === 'Z1' ? 'numeric' : 'text'"
              v-maska:[optionsCed]
              @maska="(rawValue: any) => state.identificacion = rawValue.detail.unmasked"
              :disabled="state.identificationActive || onlyCV || candidatoVerify"
              required="required"
              prepend-inner-icon="fas fa-id-card"
              :error="v$.identificacion.$error"
              :errorMessages="getErrorMessages('identificacion')"
            />
          </v-sheet>
        </v-col>
        <v-col
          cols="12"
          md="3"
        >
          <v-sheet class="ma-2">
            <InputTextComponent
              v-model.trim="state.nombre"
              :label="$t('$vuetify.nombre')"
              required="required"
              :error="v$.nombre.$error"
              :errorMessages="getErrorMessages('nombre')"
            />
          </v-sheet>
        </v-col>
        <v-col
          cols="12"
          md="3"
        >
          <v-sheet class="ma-2">
            <InputTextComponent
              v-model.trim="state.primerApellido"
              :label="$t('$vuetify.primerApellido')"
              required="required"
              :error="v$.primerApellido.$error"
              :errorMessages="getErrorMessages('primerApellido')"
            />
          </v-sheet>
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col
          cols="12"
          md="3"
        >
          <v-sheet class="ma-2">
            <InputTextComponent
              v-model.trim="state.segundoApellido"
              :label="$t('$vuetify.segundoApellido')"
              required="required"
              :error="v$.segundoApellido.$error"
              :errorMessages="getErrorMessages('segundoApellido')"
            />
          </v-sheet>
        </v-col>
        <v-col
          cols="12"
          md="3"
        >
          <v-sheet class="d-flex justify-center">
            <SwitchComponent
              v-model="state.tieneCorreo"
              :label="`${$t('$vuetify.tieneCorreo')}:
            ${state.tieneCorreo ? $t('$vuetify.si') : 'No'}`"
              @update:modelValue="!state.tieneCorreo ? state.email = '' : true"
            />
          </v-sheet>
        </v-col>
        <v-col
          v-if="state.tieneCorreo"
          cols="12"
          md="6"
        >
          <v-sheet class="ma-2">
            <InputTextComponent
              v-model.trim="state.email"
              :label="$t('$vuetify.email')"
              required="required"
              prepend-inner-icon="fas fa-envelope"
              :error="v$.email.$error"
              :errorMessages="getErrorMessages('email')"
            />
          </v-sheet>
        </v-col>
        <v-col
          cols="12"
          md="3"
        >
          <div>
            <v-menu v-model="state.menu" :close-on-content-click="false">
              <template v-slot:activator="{ props }">
                <v-sheet class="ma-2">
                  <InputTextComponent
                    v-model="state.fechaNacimiento_format"
                    @click="setNullState(['fechaNacimiento', 'fechaNacimiento_format'])"
                    v-bind="props"
                    :label="$t('$vuetify.fechaNacimiento')"
                    readonly
                    required="required"
                    prepend-inner-icon="fas fa-calendar"
                    color="secondary"
                    :error="v$.fechaNacimiento.$error"
                    :errorMessages="getErrorMessages('fechaNacimiento')"
                  />
                </v-sheet>
              </template>

              <v-date-picker
                v-model:model-value="state.fechaNacimiento"
                v-model:month="state.fechaNacimiento_month"
                @update:modelValue="cerrarMenu()"
                @update:year="state.fechaMode = 'months'"
                :max="fechaMayor"
                :min="fechaMin"
                :view-mode="state.fechaMode"
              />
            </v-menu>
          </div>

        </v-col>
        <v-col v-if="!onlyCV">
          <v-btn-primary class="mt-2" @click="submitForm()" :loading="loading" v-if="!candidatoVerify">{{$t('$vuetify.confirmar')}}</v-btn-primary>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
  <FormSubirCv v-if="onlyCV" />
</template>

<style scoped>

</style>
