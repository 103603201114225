<script setup lang="ts">
import { ref, computed } from 'vue';
import { storeToRefs } from 'pinia';
import { useAppStore } from '@/store/app';
import { useVuelidate } from '@vuelidate/core';
import * as validators from '@vuelidate/validators';
import { useI18n } from 'vue-i18n';
import { useDisplay } from 'vuetify';
import { useBase64 } from '@vueuse/core';
import type { Ref } from 'vue';
// import { useRoute } from 'vue-router';

// Composables
import useItems from '@/composables/useItems';
import useItemsGlobal from '@/composables/useItemsGlobal';
import useValidationErrors from '@/composables/useValidationErrors';
import useErrorMessages from '@/composables/useErrorMessages';
import useErrorMessagesDyn from '../composables/useErrorMessagesDyn';

// Router
// const route = useRoute();

// const paramID = route.params.id;

const {
  mesesItems,
  razonSalidaItems,
  rangoSalarioItems,
} = useItemsGlobal();

const { mdAndDown } = useDisplay();
const { t } = useI18n();

const {
  loading, initialState: state,
} = storeToRefs(useAppStore());

const {
  addExpLaboral, delExpLaboral,
  getProvinciaPais, setArchivoCV,
} = useAppStore();

const rangoFechaOptions = computed(() => {
  const inicio = [];
  for (let i = 1970; i <= 2030; i += 1) {
    inicio.push(i);
  }
  return inicio;
});

const paisItems = useItems('PAISES', 'educacion', 'LANDX', 'LAND1', false, 'pais');
const posicionItems = useItems('ACTIVIDAD', 'experienciaLaboral', 'LTEXT', 'TAETE', false, 'posicion');
const sectorEmpresarialItems = useItems('SECTOR', 'experienciaLaboral', 'BRTXT', 'BRSCH', false, 'sectorEmpresarial');
const interesItems = useItems('INTERESES', 'interes', 'DESCRIPCION', 'IDINTERES');
const vacantesItems = useItems('VACANTES', 'vacantes', 'VACANTE', 'VACANTE');

// if (paramID) {
//   state.value.vacantes = [{ text: `${paramID}`, value: `${paramID}` }];
// } else {
//   state.value.vacantes = null;
// }

const setProvinciaDyn = async (index: number, _pais: any) => {
  if (_pais) {
    state.value.experienciaLaboral[index].provinciaItems = await getProvinciaPais(_pais.value);
  }
  state.value.experienciaLaboral[index].provincia = null;
};

const { createI18nMessage } = validators;
const messagePath = ({ $validator, $params }: any) => {
  const fieldName = $params?.$field || $validator;
  return `$vuetify.validations.${fieldName}`;
};

const withI18nMessage = createI18nMessage({ t, messagePath });
const required = withI18nMessage(validators.required);
const requiredIf = (condition: any) => withI18nMessage(validators.requiredIf(condition));
const minLength = (length: number) => withI18nMessage(validators.minLength(length));
const maxLength = (length: number) => withI18nMessage(validators.maxLength(length));
const numeric = withI18nMessage(validators.numeric);

const rules = computed(() => ({
  experienciaLaboral: (state.value.expLaboral) ? {
    $each: validators.helpers.forEach({
      empresa: {
        required,
        minLength: minLength(3),
      },
      mesInicio: {
        required,
      },
      fechaInicio: {
        required,
      },
      telefono: {
        numeric,
        minLength: minLength(9),
        maxLength: maxLength(16),
      },
      pais: {
        required,
      },
      provincia: {
        required,
      },
      otraProvincia: {
        requiredIf: requiredIf((_val: any, inputData: any) => _val.length === 0 && inputData.provincia && inputData.provincia.value === 'ZZ'),
        minLength: minLength(5),
      },
      posicion: {
        required,
      },
      otraPosicion: {
        requiredIf: requiredIf((_val: any, inputData: any) => _val.length === 0
                                            && inputData.posicion
                                            && inputData.posicion.value === 9999),
        minLength: minLength(5),
      },
      sectorEmpresarial: {
        required,
      },
      otroSectorEmpresarial: {
        requiredIf: requiredIf((_val: any, inputData: any) => _val.length === 0
                                            && inputData.sectorEmpresarial
                                            && inputData.sectorEmpresarial.value === '0038'),
        minLength: minLength(5),
      },
      razonSalida: {
        requiredIf: requiredIf((_val: any, inputData: any) => !inputData.activo),
      },
      mesSalida: {
        requiredIf: requiredIf((_val: any, inputData: any) => !inputData.activo),
      },
      fechaSalida: {
        requiredIf: requiredIf((_val: any, inputData: any) => !inputData.activo),
      },
      rangoSalario: {
        required,
      },
    }),
  } : { },
  interes: {
    required,
  },
}));

const rulesFile = {
  required: (value: any) => !!value.length || t('$vuetify.validations.required', { field: t('$vuetify.archivoCV').toLowerCase() }),
  requiredValidFile: (value: any) => !value || !value.length || value[0].type === 'application/pdf' || t('$vuetify.validations.filetype'),
  rulesFiles: (value: any) => !value || !value.length || value[0].size < 2000000 || t('$vuetify.validations.fileweight'),
};

const v$ = useVuelidate(rules, state);

const errors = computed(() => useValidationErrors<any>(v$.value.$errors));

const { getErrorMessages: getErrorMessagesCampo } = useErrorMessages(v$, errors);

const formFile = ref();

const archivoCV = ref() as Ref<File>;
const { base64 } = useBase64(archivoCV);

function onFileInput(e: Event) {
  const [file] = (e.target as HTMLInputElement).files!;
  archivoCV.value = file;
}

async function submitForm() {
  const { valid }: { valid: boolean } = await formFile.value.validate();

  const check = await v$.value.$validate();
  if (valid && check) {
    setArchivoCV(base64.value);
    return true;
  }
  return false;
}

defineExpose({
  submitForm,
});

</script>
<template>
  <v-form @submit.prevent="" ref="formFile" :disabled="loading">
    <v-row no-gutters class="mb-5">
      <v-alert
        variant="outlined"
        border="start"
        color="grey-darken-1"
      >
        <v-switch
          class="pl-5 mt-0"
          v-model="state.expLaboral"
          :label="`${$t('$vuetify.expLaboral')} ${state.expLaboral ? $t('$vuetify.si') : 'No'}`"
          color="info"
          density="compact"
          hide-details
          persistent-hint
        />
      </v-alert>
    </v-row>
    <template v-if="state.expLaboral">
      <v-sheet
        class="mb-5"
        v-for="(lab, index) in state.experienciaLaboral"
        :key="index"
        border>
        <v-container class="pa-0">
          <v-row
            no-gutters
            :class="{ 'mb-10': mdAndDown }"
            justify="center"
            align="center"
          >
            <v-col class="text-center">
              <v-tooltip
                :text="$t('$vuetify.eliminar')"
                location="top"
              >
                <template v-slot:activator="{ props }">
                  <v-btn @click="delExpLaboral(index)" v-bind="props" color="red-darken-2" density="compact" icon="fa fa-minus" />
                </template>
              </v-tooltip>
            </v-col>
            <v-col
              cols="12"
              md="11"
            >

              <v-row
                no-gutters
              >
                <v-col
                  cols="12"
                  md="5"
                >
                  <v-sheet class="ma-2">
                    <InputTextComponent
                      v-model.trim="lab.empresa"
                      :label="$t('$vuetify.empresa')"
                      required="required"
                      :error="v$.experienciaLaboral.$each.$response
                        .$errors[index].empresa.length > 0"
                      :error-messages="useErrorMessagesDyn(v$.experienciaLaboral.$each.$response
                        .$errors[index].empresa, 'empresa')"
                    />
                  </v-sheet>
                </v-col>
                <v-col
                  cols="12"
                  md="2"
                >
                  <v-sheet class="ma-2">
                    <AutocompleteComponent
                      v-model="lab.mesInicio"
                      :label="$t('$vuetify.mesInicio')"
                      :items="mesesItems"
                      required="required"
                      :error="v$.experienciaLaboral.$each.$response
                        .$errors[index].mesInicio.length > 0"
                      :error-messages="useErrorMessagesDyn(v$.experienciaLaboral.$each.$response
                        .$errors[index].mesInicio, 'mesInicio')"
                    />
                  </v-sheet>
                </v-col>
                <v-col
                  cols="12"
                  md="2"
                >
                  <v-sheet class="ma-2">
                    <AutocompleteComponent
                      v-model="lab.fechaInicio"
                      :label="$t('$vuetify.fechaInicio')"
                      :items="rangoFechaOptions"
                      required="required"
                      :error="v$.experienciaLaboral.$each.$response
                        .$errors[index].fechaInicio.length > 0"
                      :error-messages="useErrorMessagesDyn(v$.experienciaLaboral.$each.$response
                        .$errors[index].fechaInicio, 'fechaInicio')"
                    />
                  </v-sheet>
                </v-col>
                <v-col
                  cols="12"
                  md="3"
                >
                  <v-sheet class="ma-2">
                    <InputTextComponent
                      v-model.trim="lab.telefono"
                      :label="$t('$vuetify.telefono')"
                      type="tel"
                      :error="v$.experienciaLaboral.$each.$response
                        .$errors[index].telefono.length > 0"
                      :error-messages="useErrorMessagesDyn(v$.experienciaLaboral.$each.$response
                        .$errors[index].telefono, 'telefono')"
                    />
                  </v-sheet>
                </v-col>
                <v-col
                  cols="12"
                  md="3"
                >
                  <v-sheet class="ma-2">
                    <AutocompleteComponent
                      v-model="lab.pais"
                      :label="$t('$vuetify.pais')"
                      :items="paisItems"
                      @update:modelValue="setProvinciaDyn(index, lab.pais)"
                      required="required"
                      :error="v$.experienciaLaboral.$each.$response
                        .$errors[index].pais.length > 0"
                      :error-messages="useErrorMessagesDyn(v$.experienciaLaboral.$each.$response
                        .$errors[index].pais, 'pais')"
                    />
                  </v-sheet>
                </v-col>
                <v-col
                  cols="12"
                  md="4"
                >
                  <v-sheet class="ma-2">
                    <AutocompleteComponent
                      v-model="lab.provincia"
                      :label="$t('$vuetify.provincia')"
                      :items="lab.provinciaItems"
                      :disabled="lab.provinciaItems && lab.provinciaItems.length === 0"
                      :error="v$.experienciaLaboral.$each.$response
                        .$errors[index].provincia.length > 0"
                      :error-messages="useErrorMessagesDyn(v$.experienciaLaboral.$each.$response
                        .$errors[index].provincia, 'provincia')"
                    />
                  </v-sheet>
                </v-col>
                <v-col
                  cols="12"
                  md="5"
                  v-if="lab.provincia && lab.provincia.value === 'ZZ'"
                >
                  <v-sheet class="ma-2">
                    <InputTextComponent
                      v-model.trim="lab.otraProvincia"
                      :label="$t('$vuetify.otraProvincia')"
                      required="required"
                      :error="v$.experienciaLaboral.$each.$response
                        .$errors[index].otraProvincia.length > 0"
                      :error-messages="useErrorMessagesDyn(v$.experienciaLaboral.$each.$response
                        .$errors[index].otraProvincia, 'otraProvincia')"
                    />
                  </v-sheet>
                </v-col>
                <v-col
                  cols="12"
                  md="5"
                >
                  <v-sheet class="ma-2">
                    <AutocompleteComponent
                      v-model="lab.posicion"
                      :label="$t('$vuetify.posicion')"
                      :items="posicionItems"
                      required="required"
                      :error="v$.experienciaLaboral.$each.$response
                        .$errors[index].posicion.length > 0"
                      :error-messages="useErrorMessagesDyn(v$.experienciaLaboral.$each.$response
                        .$errors[index].posicion, 'posicion')"
                    />
                  </v-sheet>
                </v-col>
                <v-col
                  cols="12"
                  md="3"
                  v-if="lab.posicion && lab.posicion.value === 9999"
                >
                  <v-sheet class="ma-2">
                    <InputTextComponent
                      v-model.trim="lab.otraPosicion"
                      :label="$t('$vuetify.otraPosicion')"
                      required="required"
                      :error="v$.experienciaLaboral.$each.$response
                        .$errors[index].otraPosicion.length > 0"
                      :error-messages="useErrorMessagesDyn(v$.experienciaLaboral.$each.$response
                        .$errors[index].otraPosicion, 'otraPosicion')"
                    />
                  </v-sheet>
                </v-col>
                <v-col
                  cols="12"
                  md="4"
                >
                  <v-sheet class="ma-2">
                    <AutocompleteComponent
                      v-model="lab.sectorEmpresarial"
                      :label="$t('$vuetify.sectorEmpresarial')"
                      :items="sectorEmpresarialItems"
                      required="required"
                      :error="v$.experienciaLaboral.$each.$response
                        .$errors[index].sectorEmpresarial.length > 0"
                      :error-messages="useErrorMessagesDyn(v$.experienciaLaboral.$each.$response
                        .$errors[index].sectorEmpresarial, 'sectorEmpresarial')"
                    />
                  </v-sheet>
                </v-col>
                <v-col
                  cols="12"
                  md="4"
                  v-if="lab.sectorEmpresarial && lab.sectorEmpresarial.value === '0038'"
                >
                  <v-sheet class="ma-2">
                    <InputTextComponent
                      v-model.trim="lab.otroSectorEmpresarial"
                      :label="$t('$vuetify.otroSectorEmpresarial')"
                      required="required"
                      :error="v$.experienciaLaboral.$each.$response
                        .$errors[index].otroSectorEmpresarial.length > 0"
                      :error-messages="useErrorMessagesDyn(v$.experienciaLaboral.$each.$response
                        .$errors[index].otroSectorEmpresarial, 'otroSectorEmpresarial')"
                    />
                  </v-sheet>
                </v-col>
                <v-col
                  cols="12"
                  md="3"
                >
                  <v-sheet class="d-flex justify-center">
                    <SwitchComponent
                      v-model="lab.activo"
                      :label="`${$t('$vuetify.activoEmpresa')}:
                                ${lab.activo ? $t('$vuetify.si') : 'No'}`"
                    />
                  </v-sheet>
                </v-col>
                <v-col
                  cols="12"
                  md="5"
                  v-if="!lab.activo"
                >
                  <v-sheet class="ma-2">
                    <AutocompleteComponent
                      v-model="lab.razonSalida"
                      :label="$t('$vuetify.razonSalida')"
                      :items="razonSalidaItems"
                      required="required"
                      :error="v$.experienciaLaboral.$each.$response
                        .$errors[index].razonSalida.length > 0"
                      :error-messages="useErrorMessagesDyn(v$.experienciaLaboral.$each.$response
                        .$errors[index].razonSalida, 'razonSalida')"
                    />
                  </v-sheet>
                </v-col>
                <v-col
                  cols="12"
                  md="2"
                  v-if="!lab.activo"
                >
                  <v-sheet class="ma-2">
                    <AutocompleteComponent
                      v-model="lab.mesSalida"
                      :label="$t('$vuetify.mesSalida')"
                      :items="mesesItems"
                      required="required"
                      :error="v$.experienciaLaboral.$each.$response
                        .$errors[index].mesSalida.length > 0"
                      :error-messages="useErrorMessagesDyn(v$.experienciaLaboral.$each.$response
                        .$errors[index].mesSalida, 'mesSalida')"
                    />
                  </v-sheet>
                </v-col>
                <v-col
                  cols="12"
                  md="2"
                  v-if="!lab.activo"
                >
                  <v-sheet class="ma-2">
                    <AutocompleteComponent
                      v-model="lab.fechaSalida"
                      :label="$t('$vuetify.fechaSalida')"
                      :items="rangoFechaOptions.filter(
                        op => lab.fechaInicio && op >= lab.fechaInicio)"
                      required="required"
                      :error="v$.experienciaLaboral.$each.$response
                        .$errors[index].fechaSalida.length > 0"
                      :error-messages="useErrorMessagesDyn(v$.experienciaLaboral.$each.$response
                        .$errors[index].fechaSalida, 'fechaSalida')"
                    />
                  </v-sheet>
                </v-col>
                <v-col
                  cols="12"
                  md="3"
                >
                  <v-sheet class="ma-2">
                    <AutocompleteComponent
                      v-model="lab.rangoSalario"
                      :label="$t('$vuetify.rangoSalario')"
                      :items="rangoSalarioItems"
                      required="required"
                      :error="v$.experienciaLaboral.$each.$response
                        .$errors[index].rangoSalario.length > 0"
                      :error-messages="useErrorMessagesDyn(v$.experienciaLaboral.$each.$response
                        .$errors[index].rangoSalario, 'rangoSalario')"
                    />
                  </v-sheet>
                </v-col>
                <v-col
                  cols="12"
                  md="5"
                >
                  <v-sheet class="ma-2">
                    <InputTextComponent
                      v-model.trim="lab.supervisor"
                      :label="$t('$vuetify.supervisor')"
                    />
                  </v-sheet>
                </v-col>
              </v-row>
            </v-col>
            <v-col
              class="text-center"
            >
              <v-tooltip
                :text="$t('$vuetify.agregar')"
                location="top"
              >
                <template v-slot:activator="{ props }">
                  <v-btn-primary @click="addExpLaboral()" rounded="xl" v-bind="props" density="compact" icon="fa fa-plus" />
                </template>
              </v-tooltip>
            </v-col>
          </v-row>
        </v-container>
      </v-sheet>
      <v-container>
        <v-row no-gutters>
          <v-col
            cols="12">
            <v-switch
              class="pl-5 mt-0"
              v-model="state.gpcTrabajado"
              :label="`${$t('$vuetify.trabajado')}: ${state.gpcTrabajado ? $t('$vuetify.si') : 'No'}`"
              color="info"
              density="compact"
              hide-details
              persistent-hint
            />
          </v-col>
        </v-row>
      </v-container>
    </template>
    <v-container class="pa-0">
      <v-row no-gutters>
        <v-alert
          variant="outlined"
          :title="`${$t('$vuetify.interes')} / ${$t('$vuetify.archivoCV')}:`"
          border="start"
          color="grey-darken-1"
        />
      </v-row>
      <v-row no-gutters>
        <v-col
          cols="12"
          md="6"
        >
          <v-sheet class="ma-2">
            <AutocompleteComponent
              v-model="state.interes"
              :label="$t('$vuetify.interes')"
              :items="interesItems"
              :required
              multiple
              :error="v$.interes.$error"
              :errorMessages="getErrorMessagesCampo('interes')"
              :hint="$t('$vuetify.intereshint')"
            />
          </v-sheet>
        </v-col>
        <v-col
          cols="12"
          md="6"
        >
          <v-sheet class="ma-2">
            <FileComponent
              @input="onFileInput"
              :label="$t('$vuetify.archivoCV')"
              :required
              :rules="[rulesFile.required, rulesFile.requiredValidFile, rulesFile.rulesFiles]"
              accept=".pdf"
            />
          </v-sheet>
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col
          cols="12"
          md="6"
        >
          <v-sheet class="ma-2">
            <AutocompleteComponent
              v-model="state.vacantes"
              :label="$t('$vuetify.vacantes')"
              :items="vacantesItems"
              multiple
              :hint="$t('$vuetify.vacanteshint')"
            />
          </v-sheet>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<style scoped>

</style>
