<script setup lang="ts">
import type { Ref } from 'vue';
import { ref } from 'vue';
import { useDisplay } from 'vuetify';
import { VForm } from 'vuetify/components';
import { storeToRefs } from 'pinia';
import { useAppStore } from '@/store/app';
import { useBase64 } from '@vueuse/core';
import { useI18n } from 'vue-i18n';
import { useRoute } from 'vue-router';

const store = useAppStore();

const route = useRoute();

const { t } = useI18n();

const {
  loading,
} = storeToRefs(store);
const { uploadOnlyDocument } = store;
const { mdAndDown } = useDisplay();
const archivoCV = ref() as Ref<File>;
const { base64 } = useBase64(archivoCV);

const rules = {
  required: (value: any) => !!value.length || t('$vuetify.validations.required', { field: t('$vuetify.archivoCV').toLowerCase() }),
  requiredValidFile: (value: any) => !value || !value.length || value[0].type === 'application/pdf' || t('$vuetify.validations.filetype'),
  rulesFiles: (value: any) => !value || !value.length || value[0].size < 2000000 || t('$vuetify.validations.fileweight'),
};

const formFile = ref<InstanceType<typeof VForm> | null>(null);
function onFileInput(e: Event) {
  const [file] = (e.target as HTMLInputElement).files!;
  archivoCV.value = file;
}

const submitForm = async () => {
  if (formFile.value) {
    // await formFile.value.validate();
    // console.log(base64.value);
    const { valid } = await formFile.value.validate();
    // console.log(valid);

    if (valid) {
      const fileValid = await uploadOnlyDocument(base64.value, route);
      if (fileValid) store.$reset();
    }
  }
};

</script>
<template>
  <v-form @submit.prevent="submitForm" ref="formFile">
    <v-container class="pa-0">
      <v-row no-gutters>
        <v-alert
          variant="outlined"
          :title="`${$t('$vuetify.archivoActulizar')}:`"
          border="start"
          color="grey-darken-1"
        />
      </v-row>
      <v-row justify="center" :no-gutters="mdAndDown">
        <v-col
          cols="12"
          md="9"
        >
          <v-sheet class="ma-2">
            <FileComponent
              :label="$t('$vuetify.archivoCV')"
              :hint="$t('$vuetify.archivohint')"
              :rules="[rules.required, rules.requiredValidFile, rules.rulesFiles]"
              required="required"
              @input="onFileInput"
              accept=".pdf"
            />
          </v-sheet>
        </v-col>
        <v-col
          cols="12"
          md="3"
          :class="mdAndDown ? 'text-center' : ''"
        >
          <v-btn class="mt-2" color="red-darken-2" @click="store.$reset()">{{ $t('$vuetify.cancelar') }}</v-btn> &nbsp;
          <v-btn-primary dense color="success" class="mt-2" append-icon="fa fa-arrow-right" type="submit" :loading="loading">{{ $t('$vuetify.enviar') }}</v-btn-primary>
        </v-col>
      </v-row>
    </v-container>
  </v-form>
</template>

<style scoped>

</style>
