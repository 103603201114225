<template>
  <div class="text-center">
    <v-overlay
      :model-value="loading"
      persistent
      class="align-center justify-center"
    >
      <v-progress-circular
        color="primary"
        size="64"
        indeterminate
      />
    </v-overlay>
  </div>
  <v-container>
    <!-- <v-progress-linear indeterminate :active="loading" /> -->
    <v-stepper v-model="step">
      <v-stepper-header>
        <v-stepper-item :title="$t('$vuetify.paso1')" :value="1" />
      </v-stepper-header>

      <v-stepper-window direction="vertical" v-show="step === 1">
        <v-stepper-window-item :value="1">
          <form-validar-candidato />
          <template v-if="candidatoVerify">
            <form-step1 ref="validFormStep1" />
            <v-sheet class="ma-2">
              <v-btn class="mt-2" color="red-darken-2" @click="store.$reset()">{{ $t('$vuetify.cancelar') }}</v-btn> &nbsp;
              <v-btn-primary class="mt-2" @click="submitForm('validFormStep1')">{{ $t('$vuetify.continuar') }}</v-btn-primary>
            </v-sheet>
          </template>
        </v-stepper-window-item>
      </v-stepper-window>

      <template v-if="!onlyCV">
        <v-stepper-header>
          <v-stepper-item :title="$t('$vuetify.paso2')" :value="2" />
        </v-stepper-header>

        <v-stepper-window direction="vertical" v-show="step === 2">
          <v-stepper-window-item :value="2">
            <form-step2 ref="validFormStep2" />
            <v-sheet class="ma-2">
              <v-btn class="mt-2" color="grey-darken-2" @click="step--">{{ $t('$vuetify.atras') }}</v-btn> &nbsp;
              <v-btn class="mt-2" color="red-darken-2" @click="store.$reset()">{{ $t('$vuetify.cancelar') }}</v-btn> &nbsp;
              <v-btn-primary class="mt-2" @click="submitForm('validFormStep2')">{{ $t('$vuetify.continuar') }}</v-btn-primary>
            </v-sheet>
          </v-stepper-window-item>
        </v-stepper-window>

        <v-stepper-header>
          <v-stepper-item :title="$t('$vuetify.paso3')" :value="3" />
        </v-stepper-header>

        <v-stepper-window direction="vertical" v-show="step === 3">
          <v-stepper-window-item :value="3">
            <form-step3 ref="validFormStep3" />
            <v-btn class="mt-2" color="grey-darken-2" @click="step--">{{ $t('$vuetify.atras') }}</v-btn> &nbsp;
            <v-btn class="mt-2" color="red-darken-2" @click="store.$reset()">{{ $t('$vuetify.cancelar') }}</v-btn> &nbsp;
            <v-btn-primary class="mt-2" append-icon="fa fa-arrow-right" @click="submitForm('validFormStep3')">{{ $t('$vuetify.enviar') }}</v-btn-primary>
          </v-stepper-window-item>
        </v-stepper-window>
      </template>
    </v-stepper>
  </v-container>
  <v-footer class="d-flex flex-column" color="white" v-if="paramID">
    <div class="px-4 py-2 text-center w-100">
      {{ new Date().getFullYear() }} — <strong v-if="typeof decodeParamID === 'object' && decodeParamID !== null">
        {{ decodeParamID.feriaName }}
      </strong>
      <strong v-else>
        {{ decodeParamID }}
      </strong>
    </div>
  </v-footer>
</template>

<script setup lang="ts">
import { ref } from 'vue';
import { useAppStore } from '@/store/app';
import { storeToRefs } from 'pinia';
import { useGoTo } from 'vuetify';

// Composables
import useFeriaParams from '@/composables/useFeriaParams';

const { paramID } = defineProps([
  'paramID',
]);

const goTo = useGoTo();
const decodeParamID = useFeriaParams(paramID);

const store = useAppStore();
const {
  loading, candidatoVerify, step, onlyCV,
} = storeToRefs(store);

// Define the interface for form references
interface FormComponent {
  submitForm(): Promise<boolean>;
}

// Use typed refs for form components
const validFormStep1 = ref<FormComponent | null>(null);
const validFormStep2 = ref<FormComponent | null>(null);
const validFormStep3 = ref<FormComponent | null>(null);

// Map form references to simplify form submission
const formMap = {
  validFormStep1,
  validFormStep2,
  validFormStep3,
};

const submitForm = async (formRef: keyof typeof formMap) => {
  const formComponent = formMap[formRef].value;
  if (!formComponent) return;

  try {
    const valid = await formComponent.submitForm();
    if (valid) {
      if (formRef === 'validFormStep3') {
        await store.postCandidato(paramID);
      } else if (step.value < 3) {
        step.value += 1;
      }
    } else {
      await goTo('.v-field--error', {
        offset: -80,
        easing: 'easeInQuad',
      });
    }
  } catch (error) {
    // console.error('Error al validar el form:', error);
  }
};
</script>

<style scoped>
/* Add your scoped styles here */
</style>
