import { computed } from 'vue';

export default function useFeriaParams(paramID: string) {
  return computed<string | { lang: string; code: string; feriaName: string; }>(() => {
    const decode = atob(paramID) || '';
    if (decode) {
      const [lang, code, feriaName] = decode.split('_');
      return { lang: lang.toLowerCase(), code, feriaName };
    }
    return decode;
  });
}
